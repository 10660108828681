import React from "react"
// import "./LandingPage.css"

export const Bio = () => (
    <>
        <div className="bioContainer">
            <h3 className="bio">Lowfye50 is a Nashville-based rock band storming the industry hot with groundbreaking sound and deliverance. Each member being virtuosic with unique backgrounds makes for a perfect blend of chaos and soothe. The hard-hitting band was produced and discovered by the rock industry’s very own guitarist Noah Henson. Alongside support from other names, the band tends to ride their own wave and flood the rock world with their message.
            </h3>
        </div>
    </>
)